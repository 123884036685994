import React, { useState, useEffect } from "react";
import axios from "axios";
import OrderModalComponent from "./OrderModalComponent";
import { BASE_URL } from "../../constants/url";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import OrderTableComponent from "./OrderTableComponent";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);

  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjUwMjYyOTEsInNvdXJjZSI6InNyLWF1dGgtaW50IiwiZXhwIjoxNzI1NTMxMjczLCJqdGkiOiJ5WDJmeEJkZnJaQkhBRmdwIiwiaWF0IjoxNzI0NjY3MjczLCJpc3MiOiJodHRwczovL3NyLWF1dGguc2hpcHJvY2tldC5pbi9hdXRob3JpemUvdXNlciIsIm5iZiI6MTcyNDY2NzI3MywiY2lkIjo0ODQxNDAyLCJ0YyI6MzYwLCJ2ZXJib3NlIjpmYWxzZSwidmVuZG9yX2lkIjowLCJ2ZW5kb3JfY29kZSI6IiJ9.sVD1ND6paTOQ0ITwUOtcuTML0KzvV0oAWloS5BmA3EI";

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${BASE_URL}/orders`);

      // Sort the orders by `createdAt` in descending order
      const sortedOrders = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setOrders(sortedOrders);
      setFilteredOrders(sortedOrders);
    } catch (error) {
      toast.error("Something went wrong while fetching orders");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrders();
  }, []);

  useEffect(() => {
    const results = orders.filter((order) => {
      const productMatch = order.products.some((product) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return (
        order.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        productMatch
      );
    });
    setFilteredOrders(results);
  }, [searchTerm, orders]);

  const syncOrderToShiprocket = async (order) => {
    try {
      const response = await axios.post(`${BASE_URL}/orders/syncorder`, order, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        toast.success("Order synced with Shiprocket successfully");
        getAllOrders();
        toast.success(response.data.message);
      } else {
        toast.error(
          response.data.message || "Failed to sync order with Shiprocket"
        );
      }
    } catch (error) {
      console.error("Error syncing order:", error);
      toast.error("Failed to sync order with Shiprocket");
    }
  };

  const trackOrder = async (shipmentId) => {
    if (!shipmentId) {
      toast.error("Shipment ID is missing.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/orders/trackorder`, // Make a call to your backend
        { shipmentId }, // Send shipmentId in the request body
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Check if response has tracking data
      const trackingData = response.data;
      if (trackingData && trackingData.track_url) {
        window.open(trackingData.track_url, "_blank");
      } else {
        toast.error(
          trackingData.error || "Tracking information not available yet."
        );
      }
    } catch (error) {
      console.error("Error tracking order:", error);
    }
  };

  const handleRefund = async (order) => {
    try {
      // Make an API call to handle the refund
      const response = await axios.post(`${BASE_URL}/refund`, {
        orderId: order._id,
        userId: order.userId,
        amount: order.products[0].price,
      });

      console.log(response, "199");

      toast.success("Refund processed successfully");
    } catch (error) {
      toast.error("Failed to process the refund");
    }
  };

  const columns = [
    { Header: "Order ID", accessor: "_id" },
    { Header: "User ID", accessor: "userId" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "City", accessor: "city" },
    { Header: "Address", accessor: "address" },
    { Header: "Pincode", accessor: "pincode" },
    { Header: "State", accessor: "state" },
    { Header: "Country", accessor: "country" },
    { Header: "Payment Status", accessor: "paymentStatus" },
    { Header: "Shipment Id", accessor: "shipment_id" },
    {
      Header: "Order Date",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => new Date(value).toLocaleString(), // Show both date and time
    },
    {
      Header: "Product Details",
      accessor: "products",
      Cell: ({ cell: { value } }) => (
        <div className="flex flex-wrap gap-4">
          {value.map((product, index) => (
            <div
              key={index}
              className="flex items-start space-x-4 p-2 border rounded bg-white shadow-md"
              style={{ minWidth: "300px", flex: "1 0 300px" }}
            >
              <img
                src={product.productImage}
                alt="Product"
                className="h-16 w-16 object-cover"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
              />
              <div className="flex flex-col">
                <div className="truncate">
                  Product Name: {product.productName}
                </div>
                <div className="truncate">Product ID: {product.productId}</div>
                <div className="truncate">Price: ₹{product.price}</div>
                <div className="truncate">Quantity: {product.quantity}</div>
                <div className="truncate">Subtotal: ₹{product.subtotal}</div>
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Refund",
      Cell: ({ row }) => {
        const { refundStatus, paymentStatus, status, amount } = row.original;

        if (status === "cancelled" && paymentStatus === "success") {
          if (refundStatus === "PENDING") {
            return <span className="text-[orange]">Processing...</span>; // Show "Processing" when refund is pending
          } else if (refundStatus === "SUCCESS") {
            return (
              <span className="text-[green]">Amount Refunded: {amount}</span>
            ); // Show amount refunded when successful
          } else {
            return (
              <button
                className="bg-[red] text-[white] py-1 px-3 rounded"
                onClick={() => handleRefund(row.original)}
              >
                Refund
              </button>
            );
          }
        }

        return null; // Return null if conditions are not met
      },
    },

    {
      Header: "Actions",
      accessor: "actions",
      width: 350,
      Cell: ({ row }) => {
        const { shipRocketStatus, shipment_id } = row.original;

        return (
          <div>
            {row.original.status === "cancelled" ? (
              <span className="text-red-500">Order Cancelled</span>
            ) : (
              <>
                <button
                  onClick={() => syncOrderToShiprocket(row.original)}
                  className={`text-center px-2 py-1 rounded ${
                    shipRocketStatus === "synced"
                      ? "bg-[blue] text-[white]"
                      : "bg-[green] text-[white]"
                  }`}
                  style={{ width: "200px" }}
                  disabled={shipRocketStatus === "synced"}
                >
                  {shipRocketStatus === "synced"
                    ? "Order Synced"
                    : "Sync Order to Shiprocket"}
                </button>
                <button
                  className="bg-[green] text-[white] text-center px-2 py-1 rounded mt-2"
                  style={{ width: "100px" }}
                  onClick={() => trackOrder(shipment_id)}
                >
                  Track Order
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedOrder(null);
  };

  const handleOrderUpdated = () => {
    axios
      .get(`${BASE_URL}/orders`)
      .then((response) => {
        const sortedOrders = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setOrders(sortedOrders);
        setFilteredOrders(sortedOrders);
      })
      .catch((error) => {
        toast.error("Failed to fetch updated orders");
      });
  };

  return (
    <div className="relative ml-[280px]">
      <h1 className="text-left text-2xl font-bold mt-4 mb-8">Orders</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border rounded-md w-full"
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <OrderTableComponent
          columns={columns}
          data={filteredOrders}
          customClassNames={{ table: "table-fixed", th: "p-4" }}
        />
      )}
      {modalOpen && (
        <OrderModalComponent
          isOpen={modalOpen}
          onClose={handleModalClose}
          order={selectedOrder}
          onOrderUpdated={handleOrderUpdated}
        />
      )}
    </div>
  );
};

export default Orders;
